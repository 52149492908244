import { IonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { ToastModel } from "../../models/toast.model";

interface ToastComponentProps {
  toast?: ToastModel;
}

const ToastComponent: React.FC<ToastComponentProps> = (props: ToastComponentProps) => {

  const [toast, setToast] = useState<ToastModel | undefined>(props.toast);

  const dismissAction = () => {
    setToast(undefined);
  };

  useEffect(() => {
    dismissAction();
    setTimeout(() => {
      setToast(props.toast);
    }, 400);
  }, [props.toast])

  return (
    <IonToast
      isOpen={toast !== undefined}
      onDidDismiss={() => dismissAction()}
      message={
        toast !== undefined ? toast.message : ""
      }
      position="bottom"
      color={
        toast && toast.status === true
          ? "success"
          : "danger"
      }
      duration={3500}
      buttons={[
        {
          text: "close",
          role: "cancel",
          handler: () => {
            dismissAction();
          },
        },
      ]}
    />
  );
};

export default ToastComponent;
