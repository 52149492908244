import { IonIcon } from "@ionic/react";
import { exit, notifications, settings } from "ionicons/icons";
import React from "react";
import Routes, { RoutesType } from "../../constant/route.constant";
import HeadingComponent from "../typography/heading/heading.component";
import ParagraphComponent from "../typography/paragraph/paragraph.component";

type MenuModalComponentType = {
    onRoute?: (name: RoutesType) => void;
}

const MenuModalComponent = (props: MenuModalComponentType) => {

    const onRoute = (name: RoutesType) => {
        if (props.onRoute) {
            props.onRoute(name);
        }
    }

    const menu = [
        // {
        //     title: "Alarm and Notification",
        //     route: Routes.notification,
        //     icon: notifications
        // },
        {
            title: "Settings",
            route: Routes.doctors,
            icon: settings
        },
        {
            title: "Exit",
            route: Routes.exit,
            icon: exit
        },
    ]

    return (
        <div className="flex flex-col items-start justify-end h-full pb-5 space-y-6">
            {
                menu.map((item, index) => {
                    return (
                        <div key={index} className="flex space-x-3 items-center cursor-pointer" onClick={() => onRoute(item.route)}>
                            <IonIcon slot="start" className="text-gray" icon={item.icon} size="large" />
                            <HeadingComponent className="text-white text-font-size-18">{item.title}</HeadingComponent>
                        </div>
                    )
                })
            }
        </div>
    )


}

export default MenuModalComponent;