import { DoctorsModel } from "../../models/profile/user.model";
import ResponseModel from "../../models/response.model";
import GenericThunk from ".";
import AppointmentsService from "../../api/appointments";
import { AppointmentsModel } from "../../models/appointments";

export const doctorRequest = GenericThunk<ResponseModel<DoctorsModel[]>>(
  AppointmentsService.doctorsRequest()
);

export const appointmentsSendRequest = GenericThunk<ResponseModel<AppointmentsModel[]>, {user_id: number, reserve_time: string}>(
  AppointmentsService.appointmentsSend()
);

export const removeAheadRequest = GenericThunk<ResponseModel<AppointmentsModel[]>, {id: number}>(
  AppointmentsService.appointmentsRemove()
);

export const appointmentsRequest = GenericThunk<
  ResponseModel<AppointmentsModel[]>
>(AppointmentsService.appointments());
