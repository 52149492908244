import { IRequestConfig } from '..';
import { LOGINURL, VERIFYURL } from '../url.helper';

namespace AuthService {
  // await AxiosHelper.post<ResponseModel<{ new_user?: boolean }>>(LOGINURL, param)
  export const login = (): IRequestConfig => {
    return {
      url: LOGINURL,
      method: 'POST',
      key: 'auth/login',
      payload: 'data',
    };
  };
  export const verify = (): IRequestConfig => {
    return {
      url: VERIFYURL,
      method: 'POST',
      key: 'auth/verify',
      payload: 'data',
    };
  };
}

export default AuthService;
