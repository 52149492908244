import Information from '../../components/Pages/information/information';
import ContentTemplate from '../../components/Templates/content';
import HeadingComponent from '../../components/typography/heading/heading.component';

const InformationCompletePage = () => {

    return (
        <ContentTemplate>
            <div className="px-2 md:px-8 mx- py-3 md:mx-auto max-w-5xl flex flex-col justify-between mt-8">
                <HeadingComponent className="text-primary dark:text-white">Profile</HeadingComponent>
                <div className="">
                    <Information profilePage={false} />
                </div>
            </div>
        </ContentTemplate>
    )
}

export default InformationCompletePage;