import React from 'react';
import { useIonRouter } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import SpaceComponent from '../../../components/space/space.component';
import HeadingComponent from '../../../components/typography/heading/heading.component';
import ParagraphComponent from '../../../components/typography/paragraph/paragraph.component';
import { apanaState, setConnectedDevice } from '../../../store/slice/apana.slice';

import "./index.scss";
import { resetToast, setToast } from '../../../store/slice/global.slice';

const WristbandConnections = () => {

    const { connectedDevice } = useSelector(apanaState);
    const dispatch = useDispatch();
    const route = useIonRouter();

    const connect = (number: number) => {
        dispatch(setConnectedDevice(number));
        dispatch(resetToast())
        if (number === 1) {
            dispatch(setToast({ message: "Connected a belt", status: true }))
            return;
        }
        dispatch(setToast({ message: "Disconnected a belt", status: true }))
    }

    return (
        <div className="mx-4 md:mx-auto max-w-3xl">

            <div className="px-2 flex gap-2 mt-8 cursor-pointer" onClick={() => route.goBack()}>
                <img src="/assets/images/menu/arrow-left.svg" alt="" />
                <HeadingComponent className="text-primary">Belt connections</HeadingComponent>
            </div>

            <SpaceComponent space={50} />
            <HeadingComponent className="text-center" fontSize="20px">Searching…</HeadingComponent>

            <div className="mt-4 text-center relative">
                <img className="mx-auto" src="/assets/images/wristband-connections/search-panel.svg" alt="" />
                <img className="absolute center radar" src="/assets/images/wristband-connections/radar.png" alt="" />
            </div>

            <div className="w-32 rounded-full bg-primary p-2 my-4 mx-auto">
                <ParagraphComponent className="text-white text-center">Search again</ParagraphComponent>
            </div>

            <li className="px-4 md:px-8 flex flex-col md:flex-row justify-between items-center border-b-color py-5 cursor-pointer border rounded-full">
                <div className="flex items-center">
                    <img src="/assets/images/wristband-connections/connections.svg" className='mr-2 w-6 h-6' alt="" />
                    <ParagraphComponent className="text-gray" fontSize="22px">Belt #01</ParagraphComponent>
                </div>
                <ParagraphComponent className="text-right" onClick={() => connect(connectedDevice == 1 ? 0 : 1)}>{(connectedDevice && connectedDevice > 0) ? "paired" : "select"}</ParagraphComponent>
            </li>
        </div>
    )
}


export default WristbandConnections;


type DeviceProps = {
    number?: number;
    name?: string;
    color?: string;
    className?: string;
    isConnect?: number;
    onClick: (number: number) => void;
}
export const Device = (props: DeviceProps) => {
    const onClick = () => {
        if (!props.onClick) return;
        props.onClick(props.number === props.isConnect ? -1 : props.number ?? -1);
    }
    return (
        <div className={`w-32 text-center cursor-pointer ${props.className}  ${props.isConnect === props.number ? "animate-pulse" : ""} `} onClick={onClick}>
            <div className={`w-8 h-8 rounded-full mx-auto p-1`} style={{ backgroundColor: props.color }}>
                <img src={`assets/images/wristband-connections/device.png`} />
            </div>
            <ParagraphComponent className="text-gray">{props.name}</ParagraphComponent>
        </div>
    )
}