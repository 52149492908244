import { IRequestConfig } from '..';
import { LOGINURL, VERIFYURL } from '../url.helper';

namespace ListService {
  // await AxiosHelper.post<ResponseModel<{ new_user?: boolean }>>(LOGINURL, param)
  export const report = (params?: string): IRequestConfig => {
    return {
      url: `v1/app/oximetries/report?${params}`,
      method: 'GET',
      key: 'oximetries/report',
      payload: 'params',
    };
  };
}

export default ListService;
