import React, { useEffect, useState } from 'react'
import ButtonCircle from '../../../components/Atoms/button/button.circle';
import DashboardTemplate from '../../../components/dashboard-template';
import DoctorAppointment from '../../../components/doctor-appointment';
import AppointmentModal from '../../../components/Molecules/appointment-modal';
import { useAppDispatch, useAppSelector } from '../../../store';
import { doctorAppointmentState } from '../../../store/slice/appointment.slice';
import { setToast } from '../../../store/slice/global.slice';
import { appointmentsRequest, appointmentsSendRequest, doctorRequest, removeAheadRequest } from '../../../store/thunk/doctor-appointment.thunk';

const DoctorAppointmentPage = () => {

  const dispatch = useAppDispatch();
  const [appointmentModal, setAppointmentModal] = useState<boolean>(false);
  const { doctors, appointments, loadingAppointmentId } = useAppSelector(doctorAppointmentState);

  useEffect(() => {
    dispatch(appointmentsRequest());
  }, []);

  const doctorsRequest = () => {
    dispatch(doctorRequest()).unwrap().then(() => {
      setAppointmentModal(true);
    });
  };

  const onSubmitAppointment = (doctor_id: number, reserve_time: string) => {
    dispatch(
      appointmentsSendRequest({
        user_id: doctor_id,
        reserve_time: reserve_time,
      })
    ).unwrap().then((response) => {
      setAppointmentModal(false);
      dispatch(setToast({message: response.message, status: true}))
    });
  };

  const onRemove = (id: number) => {
    dispatch(
      removeAheadRequest({
        id: id
      })
    ).unwrap().then(response => {
      dispatch(setToast({message: response.message, status: true}))
    })
  }

  return (
    <DashboardTemplate tab='doctor-appointment'>

      <DoctorAppointment appointments={appointments} onRemove={onRemove} loadingAppointmentId={loadingAppointmentId} />

      <ButtonCircle onClick={doctorsRequest} />

      <AppointmentModal
        show={appointmentModal}
        onClose={() => setAppointmentModal(false)}
        doctors={doctors}
        onSubmit={onSubmitAppointment}
      />

    </DashboardTemplate>
  )
}

export default DoctorAppointmentPage;