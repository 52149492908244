import ResponseModel from '../../models/response.model';
import GenericThunk from '.';
import AuthService from '../../api/auth';
import { verifyRequestInputModel, verifyRequestOutputModel } from '../../models/verifyRequestModel';

export const loginRequest = GenericThunk<ResponseModel<{
  message?: string;
  seconds?: number;
  success?: boolean;
}>,
  { email: string }>(AuthService.login());

export const verifyRequest = GenericThunk<ResponseModel<verifyRequestOutputModel>,
  verifyRequestInputModel>(AuthService.verify());