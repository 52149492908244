
import { useIonRouter } from "@ionic/react";
import Routes, { RoutesEnum, RoutesItems, RoutesType } from "../constant/route.constant";

export const useRouter = () => {
    const router = useIonRouter();

    const push = (route: RoutesType, replace: boolean = false) => {
        // , 'none', replace === true ? "replace" : "push"
        router.push(route.path);
    };

    const replace = (route: RoutesType, replace: boolean = false) => {
        router.push(route.path, 'forward', replace === true ? "replace" : "push");
    };

    const goBack = () => {
        router.goBack();
    }

    return { push, goBack, replace };
}