import { PximetriesValueModel } from '../../models/oximetries/report.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { combineReducersModel, RootState } from '../..';
import { UserModel } from '../../models/profile/user.model';
import { loginRequest, verifyRequest } from '../thunk/auth.thunk';
import { RootState } from '..';
import { medicalAdviceRequest } from '../thunk/user.thunk';
import { MedicalAdviceModel } from '../../models/medical_advice';

export interface IMedicalAdvice {
  loading?: boolean;
  medicalAdvice?: MedicalAdviceModel[];
}

const initialState: IMedicalAdvice = {
  medicalAdvice: []
};

const MedicalAdvice = createSlice({
  name: 'MedicalAdvice',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(medicalAdviceRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(medicalAdviceRequest.fulfilled, (state, action) => {
      state.medicalAdvice = action.payload.data;
      state.loading = false;
    });

    builder.addCase(medicalAdviceRequest.rejected, (state) => {
      state.loading = false;
    });
    //=====================================================
    // builder.addCase(verifyRequest.pending, (state) => {
    //   state.loading = true;
    // });

    // builder.addCase(verifyRequest.fulfilled, (state, action) => {
    //   state.loading = false;
    //   localStorage.setItem('user_id', action.payload.data?.user_id + '');
    //   localStorage.setItem(
    //     'access_token',
    //     action.payload.data?.access_token + ''
    //   );
    //   localStorage.setItem('image', action.payload.data?.image + '');
    // });

    // builder.addCase(verifyRequest.rejected, (state) => {
    //   state.loading = false;
    // });
  },
});

export const medicalAdviceState = (state: RootState) => state.medicalAdvice;
// setMobile, setNewUser
export const {} = MedicalAdvice.actions;
export default MedicalAdvice.reducer;
