import React from 'react';

type HeadingComponentProps = {
    children?: React.ReactNode;
    className?: string;
    color?: string;
    fontSize?: string;
    changeClassColor?: boolean;
}

const HeadingComponent = (props: HeadingComponentProps) => {
    return (
        <h1 className={`${props.className} p-0 m-0 text-font-size-28 ${!props.changeClassColor ? "dark:text-white" : ""}`} style={{color: props.color ?? "", fontSize: props.fontSize ?? ""}}>{props.children}</h1>
    )
}

export default HeadingComponent;