import ButtonCircle from "../../components/Molecules/button";
import ContentTemplate from "../../components/Templates/content";
import HeadingComponent from "../../components/typography/heading/heading.component";
import ParagraphComponent from "../../components/typography/paragraph/paragraph.component";
import Routes from "../../constant/route.constant";
import { useRouter } from "../../hook/useRouter";

const WelcomePage = () => {

    const route = useRouter();

    const next = () => {
        const access_token = localStorage.getItem("access_token");
        route.push(access_token ? Routes.dashboard : Routes.login);
    }

    return (
        <ContentTemplate>
            <div className="grid grid-rows-12 h-screen py-4 px-8 justify-center items-center max-w-xl mx-auto">
                <div className="row-span-11">
                    <img style={{ height: "55vh" }} src="/assets/images/welcome/welcome-image.png" className="mx-auto" alt="" />
                    <ParagraphComponent className="text-primary my-6">Welcome to the</ParagraphComponent>
                    <HeadingComponent fontSize={"34px"} className="text-primary leading-tight">Smart Fetus <br /> Monitoring <br /> System</HeadingComponent>
                </div>
                <div className="col-row-1">
                    <div className="flex items-center justify-end gap-2">
                        <ParagraphComponent className="text-gold">Let's Start</ParagraphComponent>
                        <ButtonCircle onClick={next} />
                    </div>
                </div>
            </div>
        </ContentTemplate>
    )
}
export default WelcomePage;