const getTime = (time: number) => {
  if (time === 0) return "";
  let minutes = Math.floor(time / 60);
  let seconds = time % 60;
  const m = String(minutes).padStart(2, "0");
  const s = String(seconds).padStart(2, "0");
  return (m + ":" + s);
}
const getDate = (date: string) => new Date(date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })

export {
  getTime,
  getDate
}

