import React, { CSSProperties } from 'react';
import LoadingSvg from '../loading';

type ButtonComponentProps = {
    label?: string;
    active?: boolean;
    onClick?: () => void;
    className?: string;
    children?: React.ReactNode;
    circle?: boolean;
    loading?: boolean;
}

const ButtonComponent = (props: ButtonComponentProps) => {

    let wrapper = `${!props.className?.includes("rounded") ? "rounded-3xl" : ""} ${(props.active === true ? "gradient" : " text-white bg-gray")} ${props.className ?? ""}`;

    if (props.circle) {
        wrapper += " w-12 h-12 rounded-full flex items-center justify-center";
    } else {
        if (!wrapper.includes("py-")) {
            wrapper += " py-4";
        }
        wrapper += " px-8";
    }

    return (
        <button onClick={props.onClick} className={wrapper}>
            {
                props.loading ? <LoadingSvg /> : (props.children ? props.children : props.label)

            }
        </button>
    )
}

export default ButtonComponent;