import React from 'react'

type ButtonCircleProps = {
  onClick?: () => void;
}
const ButtonCircle = (props: ButtonCircleProps) => {
  return (
    <div
      className="bg-white shadow cursor-pointer rounded-full w-12 h-12 md:w-16 md:h-16 flex justify-center items-center fixed right-4 md:right-8 bottom-24 z-50"
      onClick={props.onClick}
    >
      <img
        src="/assets/images/message/plus.svg"
        className="w-5 h-5"
        alt=""
      />
    </div>
  )
}
export default ButtonCircle