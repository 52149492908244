import React, { HTMLAttributes } from 'react';

type ParagraphComponentProps = {
    children?: React.ReactNode;
    className?: string;
    color?: string;
    fontSize?: string;
    onClick?: () => void;
    changeColorClass?: boolean
} & HTMLAttributes<HTMLParagraphElement>

const ParagraphComponent = (props: ParagraphComponentProps) => {
    return (
        <p
            className={`text-font-size-14 ${!props.changeColorClass ? "dark:text-white" : ""} ${props.className}`}
            onClick={props.onClick}
            style={{ color: props.color ?? "", fontSize: props.fontSize ?? "" }}>{props.children}</p>
    )
}

export default ParagraphComponent;