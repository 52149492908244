import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateObject from "react-date-object";

import moment from "moment";

type DatePickerProps = {
  onSelect?: (date: any) => void;
  onClose?: (date?: any) => void;
  date?: Date;
  isShow?: boolean;
  showTimeSelect?: boolean;
  format?: 'YYYY-MM-DD' | "YYYY-MM-DD hh:mm:a";
};

const DatePickerComponent = (props: DatePickerProps) => {

  const [startDate, setStartDate] = useState(props.date ?? new Date());

  useEffect(() => {
    onChange(startDate);
  }, [startDate])

  const onChange = (date: any) => {
    console.log(date);

    if (!props.onSelect) return;

    let dateObject = new DateObject(date);
    const dateFormat = props.format === 'YYYY-MM-DD' ? dateObject.format(props.format) : convertDateTo24Hour(dateObject.format(props.format));
    props.onSelect(dateFormat);
    if (props.onClose) props.onClose();
  };

  const convertDateTo24Hour = (date: string) => {
    let elem = date.split(" ");
    let stSplit = elem[1].split(":");
    let stHour = Number(stSplit[0]);
    let stMin = stSplit[1];
    let stAmPm = stSplit[2];

    if (stAmPm == "pm") {
      if (stHour != 12) {
        stHour = stHour * 1 + 12;
      }
    } else if (stAmPm == "am" && stHour == 12) {
      stHour = stHour - 12;
    } else {
      stHour = stHour;
    }
    return elem[0] + " " + stHour + ":" + stMin;
  };

  return (
    <>
      <div
        className={
          "fixed z-50 inset-0 overflow-y-auto bg-manatee bg-opacity-40 duration-100 transition-opacity " +
          (props.isShow === true
            ? "opacity-1"
            : "opacity-0 pointer-events-none")
        }
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className={`flex items-center justify-center min-h-screen pt-4 md:px-4 pb-b-20 text-center sm:block sm:p-0`}
        >
          <div
            className="fixed inset-0 bg-gray-500 transition-opacity bg-black opacity-50"
            aria-hidden="true"
            onClick={props.onClose}
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            style={{ maxHeight: "70vh" }}
            className={`border border-white mx-4 p-3 inline-block align-center bg-white rounded-3xl text-left shadow-xl transform transition-all sm:my-8 align-middle sm:max-w-lg`}
          >
            <div className="w-full relative rounded">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date as Date)}
                // onSelect={(date) => console.log(date)}
                inline
                yearDropdownItemNumber={100}
                showYearDropdown={true}
                shouldCloseOnSelect={false}
                maxDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
                // minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
                // showTimeInput={true}
                showTimeSelect={props.showTimeSelect}
                focusSelectedMonth={true}
              // dateFormat="MMMM d, yyyy"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePickerComponent;
