import Input from '../../components/input/input';
import LogoComponent from '../../components/logo/logo.component';
import ParagraphComponent from '../../components/typography/paragraph/paragraph.component';
import Routes from '../../constant/route.constant';
import { useAppDispatch, useAppSelector } from '../../store';
import { authState, setEmail } from '../../store/slice/auth.slice';
import { setModal } from '../../store/slice/global.slice';
import { loginRequest } from '../../store/thunk/auth.thunk';
import ButtonCircle from '../../components/Molecules/button';
import ContentTemplate from '../../components/Templates/content';
import { useRouter } from '../../hook/useRouter';

const LoginPage = () => {

    const dispatch = useAppDispatch();
    const { loading, email } = useAppSelector(authState)
    const router = useRouter();

    const login = () => {
        if (!email) {
            dispatch(setModal({ message: "Please enter email" }));
            return;
        }
        dispatch(setModal(undefined));
        dispatch(loginRequest({ email: email })).then(response => {
            if (response.meta.requestStatus === "rejected") {
                const error = response.payload as any;
                dispatch(setModal({ message: error.message }))
                return;
            }
            router.push(Routes.otp);
        });
    }

    const onChangeEmail = (email: string) => {
        dispatch(setEmail(email));
    }

    return (
        <ContentTemplate>
            <div className="h-screen flex flex-col items-center">
                <div className="flex flex-col justify-between items-center h-3/4 my-auto">
                    <LogoComponent className="" />
                    <div className="space-y-4 text-center w-72">
                        <ParagraphComponent className="text-gray">Email</ParagraphComponent>
                        <Input
                            type="email"
                            value={email}
                            onChange={(text) => onChangeEmail(text)}
                            readOnly={false}
                            align="text-center"
                            onEnterDown={login}
                        />
                        <ButtonCircle onClick={login} loading={loading} />
                    </div>
                </div>
            </div>
        </ContentTemplate>
    )
}
export default LoginPage;