import { IRequestConfig } from "..";
import { AppointmentsRemoveURL, AppointmentsSendURL, AppointmentsURL, UserDoctorsURL } from "../url.helper";

namespace AppointmentsService {

  export const doctorsRequest = (): IRequestConfig => {
    return {
      url: UserDoctorsURL,
      method: "GET",
      key: "app/user/doctors",
    };
  };

  export const appointments = (): IRequestConfig => {
    return {
      url: AppointmentsURL,
      method: "GET",
      key: "app/user/appointments",
    };
  };

  export const appointmentsSend = (): IRequestConfig => {
    return {
      url: AppointmentsSendURL,
      method: "POST",
      key: "app/user/appointments/send",
      payload: "data",
    };
  };

  export const appointmentsRemove = (): IRequestConfig => {
    return {
      url: AppointmentsRemoveURL,
      method: "DELETE",
      key: "app/user/appointments/remove",
      payload: "data",
    };
  };

}

export default AppointmentsService;
