import { BASEURLASSETS } from '../../api/url.helper';
import { MedicalAdviceModel } from '../../models/medical_advice';
import { getDate } from '../../utils/date';
import HeadingComponent from '../typography/heading/heading.component';
import ParagraphComponent from '../typography/paragraph/paragraph.component';

type MedicalAdviceProps = {
    data?: MedicalAdviceModel[];
    onReplay?: (item?: MedicalAdviceModel) => void;
}

const MedicalAdvice = (props: MedicalAdviceProps) => {

    const onReplay = (item: MedicalAdviceModel) => {
        if (!props.onReplay) return;
        props.onReplay(item)
    }


    return (
        <div className="px-4 mt-4 space-y-3">
            {
                props.data && props.data.map(item => {
                    return (
                        <>
                            <div className="flex gap-3">
                                <img src={BASEURLASSETS + item.img} alt="" className='w-12 h-12 rounded-full' />
                                <div className="flex w-full items-center justify-between">
                                    <div>
                                        <HeadingComponent fontSize="20px" className="text-primary">{item.name}</HeadingComponent>
                                        <ParagraphComponent fontSize="12px" className="text-gray2">{getDate(item.created_at ?? "")}</ParagraphComponent>
                                    </div>
                                    <div className="flex gap-2 cursor-pointer">
                                        <ParagraphComponent color="#868167" className="text-right" onClick={() => onReplay(item)}>Reply</ParagraphComponent>
                                        <img src="/assets/images/medical-advice/reply.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <ParagraphComponent color="#868167">{item.message}</ParagraphComponent>
                            {
                                item.advices && item.advices.map(advice => {
                                    return (
                                        <div className="pl-6 mt-3">
                                            <div className="flex flex-col md:flex-row gap-3">
                                                <img src={BASEURLASSETS + advice.img} alt="" className='w-12 h-12 rounded-full' />
                                                <div className="flex flex-col items-start justify-center">
                                                    <HeadingComponent fontSize="20px" className="text-primary">{advice.name}</HeadingComponent>
                                                    <ParagraphComponent fontSize="12px" className="text-gray2">{getDate(advice.created_at ?? "")}</ParagraphComponent>
                                                    <ParagraphComponent className='text-gray2'>{advice.message}</ParagraphComponent>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="h-0.5 bg-gray"></div>
                        </>
                    )
                })
            }
        </div>
    )
}

export default MedicalAdvice;