import { IonIcon } from "@ionic/react"
import { arrowForward } from "ionicons/icons"
import ButtonComponent from "../../Atoms/button/button.component"

type ButtonCircleProps = {
  loading?: boolean;
  onClick: () => void;
}

const ButtonCircle = (props: ButtonCircleProps) => {
  return (
    <div className={`flex items-center justify-center`}>
      <ButtonComponent
        className="text-white font-bold"
        circle={true}
        active={true}
        onClick={props.onClick}
        loading={props.loading}
      >
        <IonIcon
          icon={arrowForward}
          className="text-white"
          size="small"
        />
      </ButtonComponent>
    </div>
  )
}
export default ButtonCircle;