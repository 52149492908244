import React, { useEffect } from 'react'
import { BASEURLASSETS } from '../../../api/url.helper';
import ButtonComponent from '../../../components/Atoms/button/button.component';
import LoadingComponent from '../../../components/loading/loading.component';
import HeadingComponent from '../../../components/typography/heading/heading.component';
import { DoctorsModel } from '../../../models/profile/user.model';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setToast } from '../../../store/slice/global.slice';
import { userState } from '../../../store/slice/user.slice';
import { doctorsRequest, userTodoctorsRequest } from '../../../store/thunk/user.thunk';

const Doctors = () => {

    const dispatch = useAppDispatch();
    const { doctors, loading } = useAppSelector(userState);

    useEffect(() => {
        dispatch(doctorsRequest());
    }, [])

    const styleStatus = (doctor_status?: string) => {
        if (doctor_status === "confirm") return "text-green";
        if (doctor_status === "pending") return "text-yellow";
        return "";
    }

    const submit = (doctor: DoctorsModel) => {
        if (doctor.status === "reject" || doctor.status === "confirm") return;
        let type = "send"
        if (doctor.status) type = "delete";
        if (window.confirm("Are you sure about " + type + " the request?")) {
            request(doctor.id);
        }
    }

    const request = (doctor_id: number) => {
        dispatch(userTodoctorsRequest({ doctor_id: doctor_id })).unwrap().then(response => {
            dispatch(doctorsRequest());
            dispatch(setToast({message: response.message, status: true}))
        });
    }

    const labelButton = (status?: string) => {
        if (status === "confirm") return "connected";
        if (status === "reject") return "rejected";
        if (status === "pending") return "delete";
        return "connect";
    }

    return (
        <div className="profile mx-2 mb-4 md:mx-auto max-w-5xl">
            <div className="flex md:px-8 my-4 md:my-8">
                <HeadingComponent className="text-primary dark:text-white">Connect to Doctors</HeadingComponent>
            </div>
            {
                loading && (
                    <LoadingComponent isLoading={true} />
                )
            }
            <ul className='md:px-8 grid grid-cols-2 md:grid-cols-5 gap-4'>
                {
                    doctors && doctors.map(doctor => {
                        return (
                            <li key={doctor.id} className='flex justify-center flex-col p-2 border border-gray rounded-xl gap-3 text-center'>
                                <img src={BASEURLASSETS + doctor.img} alt={doctor.img} className="h-16" />
                                {doctor.name}
                                <h5 className={`${styleStatus(doctor.status)}`}>{doctor.status ?? ''}&nbsp;</h5>
                                <ButtonComponent active={(doctor.status === "reject" || doctor.status === "confirm") ? false : true} className="text-white py-1" onClick={() => submit(doctor)}>{labelButton(doctor.status)}</ButtonComponent>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Doctors;