import {
  NavContext,
} from "@ionic/react";
import React, { useContext } from "react";
import "./header.component.scss";
import { RoutesType } from "../../constant/route.constant";
import LogoComponent from "../logo/logo.component";

interface ContainerProps {
  callback?: Function;
  title?: string;
  title_icon?: string;
  isBack?: boolean;
  rightIcon?: string;
  rightCallBack?: Function;
  rightIconIsPopover?: boolean;
  RightComponent?: JSX.Element;
  openMenu?: () => void;

  apnea?: boolean;
  onApana?: (status: boolean) => void;

  route?: RoutesType
}

const HeaderComponent: React.FC<ContainerProps> = ({
}) => {

  return (
    <div className="header-theme z-10">
      <div className="h-12 px-2 flex justify-start items-center bg-gray-gold dark:bg-black">
        <LogoComponent />
      </div>
    </div>
  );
};

export default HeaderComponent;
