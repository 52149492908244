// export const BASEURL = "http://192.168.8.116:8000/api/";
// export const BASEURLASSETS = "http://192.168.8.116:8000/";

export const BASEURL = "https://api.smartfetus.ca/api/";
export const BASEURLASSETS = "https://api.smartfetus.ca/";

export const LOGINURL = `/v1/auth/login`;
export const VERIFYURL = `/v1/auth/verify`;

export const USERURL = `/v1/app/user`;
export const USERDOCTORALLSURL = `/v1/app/user/doctors/all`;
export const USERDOCTORREQUESTSURL = `/v1/app/user/doctors/request`;
export const USERUPDATWURL = `/v1/app/user/update`;
export const USERUPDATImageWURL = `/v1/app/user/update-profile-image`;

export const REPORTURL = `/v1/report`;

// appointments
export const AppointmentsSendURL = `/v1/app/user/appointments/send`;
export const AppointmentsRemoveURL = `/v1/app/user/appointments/remove`;
export const AppointmentsURL = `/v1/app/user/appointments`;
export const UserDoctorsURL = `/v1/app/user/doctors`;

export const MedicalAdviceURL = `/v1/app/user/medical-advice`;
export const MedicalAdviceSendURL = `/v1/app/user/medical-advice/send`;

