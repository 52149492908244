import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducersModel } from '..';
import { PximetriesModel } from '../../models/oximetries/report.model';
import { report } from '../thunk/oximetries.thunk';

export interface PximetriesSliceModel {
  pximetries: PximetriesModel[];
  pximetriesDetail: PximetriesModel[];
  chart: PximetriesModel[];
  loading: boolean;
}

const initialState: PximetriesSliceModel = {
  pximetries: [],
  pximetriesDetail: [],
  chart: [],
  loading: false,
};

const PximetriesSlice = createSlice({
  name: 'PximetriesSlice',
  initialState,
  reducers: {
    setPximetries(state, { payload }) {
      state.pximetries = payload;
    },
    setPximetriesDetail(state, { payload }) {
      state.pximetriesDetail = payload;
    },
    setChart(state, { payload }) {
      state.chart = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(report().pending, (state) => {
      state.loading = true;
    });

    builder.addCase(report().fulfilled, (state, action: PayloadAction<any>) => {
      state.pximetries = action.payload.data;
      state.loading = false;
    });

    builder.addCase(report().rejected, (state) => {
      state.loading = false;
    });
    //=====================================================
  },
});

export const pximetriesState = (state: combineReducersModel) =>
  state.pximetries;

export const { setPximetries, setPximetriesDetail, setChart } =
  PximetriesSlice.actions;
export default PximetriesSlice.reducer;
