import { IonContent, IonPage } from "@ionic/react";
import Information from "../../../components/Pages/information/information";
import SpaceComponent from "../../../components/space/space.component";
import HeadingComponent from "../../../components/typography/heading/heading.component";
import ParagraphComponent from "../../../components/typography/paragraph/paragraph.component"

import { RoutesEnum } from "../../../constant/route.constant";

const ProfilePage = () => {

  const logout = () => {
    localStorage.clear();
    document.location.href = RoutesEnum.welcome;
  };

  return (
    // <div className="mx-2 md:mx-auto max-w-5xl">
    <IonContent>
      <div className="px-2 md:px-8 mx-2 md:mx-auto max-w-5xl flex justify-between mt-8">
        <HeadingComponent className="text-primary dark:text-white">Profile</HeadingComponent>
        <div className="">
          <div
            className="rounded-xl w-16 h-8 bg-gray-gold dark:bg-gray flex justify-center items-center cursor-pointer"
            onClick={logout}
          >
            <ParagraphComponent className="text-heart">
              Logout
            </ParagraphComponent>
          </div>
        </div>
      </div>
      <div className="px-2 md:px-8 mx-2 md:mx-auto max-w-5xl">
        <Information
          profilePage={true}
        />
      </div>

      <SpaceComponent space={30} />
    </IonContent>
    // </div>
  );
};

export default ProfilePage;
