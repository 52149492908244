import React, { useEffect, useState } from 'react'
import { BASEURLASSETS } from '../../../api/url.helper';
import ButtonCircle from '../../../components/Atoms/button/button.circle';
import DashboardTemplate from '../../../components/dashboard-template';
import MedicalAdvice from '../../../components/medical-advice';
import Modal from '../../../components/modal-tailwind';
import SelectDoctor from '../../../components/select-doctor';
import HeadingComponent from '../../../components/typography/heading/heading.component';
import ParagraphComponent from '../../../components/typography/paragraph/paragraph.component';
import { MedicalAdviceModel } from '../../../models/medical_advice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { doctorAppointmentState } from '../../../store/slice/appointment.slice';
import { setModal } from '../../../store/slice/global.slice';
import { medicalAdviceState } from '../../../store/slice/medical-advice.slice';
import { doctorRequest } from '../../../store/thunk/doctor-appointment.thunk';
import { medicalAdviceRequest, submitAdviceRequest } from '../../../store/thunk/user.thunk';

const MedicalAdvicePage = () => {

  const dispatch = useAppDispatch();
  const { medicalAdvice } = useAppSelector(medicalAdviceState);
  const [replayModal, setReplyModal] = useState<MedicalAdviceModel | undefined>(undefined);
  const [adviceMessage, setAdviceMessage] = useState("");
  const { doctors } = useAppSelector(doctorAppointmentState);
  const [doctorId, setDoctorId] = useState<number>();

  const onReplay = (item?: MedicalAdviceModel) => {
    setReplyModal(item);
  };

  useEffect(() => {
    dispatch(medicalAdviceRequest({}));
    doctorsRequest();
  }, []);

  const doctorsRequest = () => {
    return dispatch(doctorRequest()).unwrap();
  };

  const circleButton = () => {
    if (doctors && doctors.length > 0) {
      setReplyModal({});
      return;
    }
    doctorsRequest().then(() => {
      setReplyModal({});
    })
  }

  const OnSubmitAdvice = () => {

    let key = replayModal?.id === 0 ? "subject" : "message_id";
    let value = replayModal?.id === 0 ? "" : replayModal?.id;

    const to_user_id = replayModal?.user_id ?? doctorId;

    if (!to_user_id || Number(to_user_id) < 1) {
      dispatch(setModal({ message: "Please select a doctor" }))
      return;
    }

    if (!adviceMessage) {
      dispatch(setModal({ message: "Please enter your message" }))
      return;
    }

    dispatch(
      submitAdviceRequest({
        to_user_id: to_user_id,
        message: adviceMessage,
        [key]: value,
      })
    ).unwrap()
      .then(() => {
        setReplyModal(undefined);
        setAdviceMessage("")
        dispatch(medicalAdviceRequest({}));
      });
  };

  return (
    <DashboardTemplate tab='medical-advice'>

      <MedicalAdvice data={medicalAdvice} onReplay={onReplay} />

      <ButtonCircle onClick={circleButton} />

      <Modal
        show={replayModal !== undefined ? true : false}
        onClose={() => setReplyModal(undefined)}
      >
        <div className="p-4 md:p-8 shadow space-y-4 md:space-y-8">
          <div className="flex justify-between">
            <ParagraphComponent fontSize="20px" color="#1D4068">
              Write a message for
            </ParagraphComponent>
            <img
              src="/assets/images/close.svg"
              className="cursor-pointer"
              onClick={() => setReplyModal(undefined)}
              alt=""
            />
          </div>
          {
            replayModal?.id && (
              <div className="flex gap-3">
                <img src={BASEURLASSETS + replayModal?.img} alt="" className="h-16 rounded-full" />
                <div className="flex flex-col items-start justify-center">
                  <HeadingComponent fontSize="20px" className="text-primary dark:text-primary" changeClassColor={true}>
                    {replayModal?.name}
                  </HeadingComponent>
                </div>
              </div>
            )
          }
          <SelectDoctor doctors={doctors} onSelect={(doctor_id) => setDoctorId(doctor_id)} />
          <textarea
            onChange={(e) => setAdviceMessage(e.target.value)}
            value={adviceMessage}
            rows={5}
            placeholder="message"
            className="p-4 border border-gray w-full rounded-xl outline-none"
            style={{ backgroundColor: "#F7F4E6", color: "#69717B" }}
          ></textarea>
          <div className="flex justify-end items-end">
            <div
              className="bg-primary flex py-4 px-8 cursor-pointer rounded-full text-white gap-2"
              onClick={OnSubmitAdvice}
            >
              <ParagraphComponent fontSize="16px" className="font-bold">send</ParagraphComponent>
              <img src={`/assets/images/medical-advice.svg`} alt="" />
            </div>
          </div>
        </div>
      </Modal>
    </DashboardTemplate>
  )
}
export default MedicalAdvicePage;