import { IonContent, useIonRouter } from '@ionic/react'
import React from 'react'
import { TabType } from '../../models/types/tab';
import SpaceComponent from '../space/space.component';
import Tabs from '../tabs'
type DashboardTemplateProps = {
  tab?: TabType;
  children?: React.ReactNode
}
const DashboardTemplate = (props: DashboardTemplateProps) => {
  const router = useIonRouter();
  return (
    <IonContent className="">
      <div className="max-w-3xl md:mx-auto md:h-56 overflow-hidden relative">
        <span
          className="absolute inset-0 opacity-60"
          style={{ backgroundColor: "#FD7279" }}
        ></span>
        <img
          src="/assets/images/home/background.png"
          className="w-full"
          alt=""
        />
      </div>
      <div className="px-1 md:px-2 max-w-3xl md:mx-auto">
        <Tabs tab={props.tab ?? 'heart-rate'} onChange={(tab) => router.push(tab)} />
        {props.children}
        <SpaceComponent />
      </div>
    </IonContent>
  )
}
export default DashboardTemplate