import { IonContent, IonModal } from "@ionic/react";
import React from "react";
import "./modal.component.scss";

interface ContainerProps {
  showModal: boolean;
  onDidDismiss?: Function;
  children?: React.ReactNode;
}

const ModalComponent: React.FC<ContainerProps> = ({
  showModal = false,
  onDidDismiss,
  children
}) => {
  const dismissAction = () => {
    if (onDidDismiss) {
      onDidDismiss();
    }
  };

  return (
    <IonModal
      isOpen={showModal}
      cssClass="modal_bottom"
      swipeToClose={true}
      backdropDismiss={true}
      showBackdrop={true}
      onDidDismiss={() => dismissAction()
      }
    >
      <IonContent>
        {/* <div className="content"> */}
          {children}
        {/* </div> */}
      </IonContent>
    </IonModal>
  );
};

export default ModalComponent;
