import { IonContent, useIonRouter } from '@ionic/react';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Routes from '../constant/route.constant';
import HomePage from '../pages/dashboard/home/home.page';
import ProfilePage from '../pages/dashboard/profile/profile.page';
import WristbandConnections from '../pages/dashboard/wristband-connections';
import MasterLayout from './master.layout';
import MorePage from '../pages/dashboard/more/more.page';
import Doctors from '../pages/dashboard/doctors';
import MedicalAdvicePage from '../pages/dashboard/home/medical-advice';
import DoctorAppointmentPage from '../pages/dashboard/home/doctor-appointment';

const DashboardLayout = () => {
    const router = useIonRouter();
    useEffect(() => {
        const access_token = localStorage.getItem("access_token");
        if (!access_token) {
            // router.push(Routes.welcome.path);
            window.location.href = Routes.welcome.path;
        }
    }, [])

    return (
        <MasterLayout>
            <IonContent fullscreen className="text-white">
                <Switch>
                    <Route path={Routes.home.path} component={HomePage} />
                    
                    <Route path={Routes.medicalAdvice.path} component={MedicalAdvicePage} />
                    <Route path={Routes.doctorAppointment.path} component={DoctorAppointmentPage} />


                    <Route path={Routes.profile.path} component={ProfilePage} />
                    <Route path={Routes.doctors.path} component={Doctors} />
                    <Route path={Routes.wristbandConnections.path} component={WristbandConnections} />
                    <Route path={Routes.more.path} component={MorePage} />
                    <Redirect from={Routes.dashboard.path} to={Routes.home.path} />
                </Switch>
            </IonContent>
        </MasterLayout>
    )
}

export default DashboardLayout;