import React, { useState } from 'react';
import { chevronForward } from 'ionicons/icons';
import ParagraphComponent from '../../../components/typography/paragraph/paragraph.component';
import Routes, { RoutesEnum } from '../../../constant/route.constant';

import { IonIcon, useIonRouter } from "@ionic/react";

import HeadingComponent from '../../../components/typography/heading/heading.component';
import { MOREDATA } from './more.data';
import { MenuModel } from '../../../models/menu';

const MorePage = () => {

    const router = useIonRouter();
    const [menus, setMenus] = useState<{ items: MenuModel[], isBack: boolean, title?: string }>({ items: MOREDATA, isBack: false, title: "More" });

    const onClick = (item: MenuModel) => {
        if (item.route === Routes.exit.path) {
            localStorage.clear();
            document.location.href = RoutesEnum.welcome;
            return;
        }
        if (item.children) {
            setMenus({ isBack: true, items: item.children, title: item.title });
            return;
        }
        router.push(item.route ?? "/");
    }
    const back = () => {
        setMenus({ items: MOREDATA, isBack: false, title: "More" })
    }

    return (
        <div className="setting mx-4 md:mx-auto max-w-3xl">
            <div className="px-2 flex gap-2 mt-8 cursor-pointer" onClick={back}>
                {
                    menus.isBack && (
                        <>
                            <img className='block dark:hidden' src="/assets/images/menu/arrow-left.svg" alt="" />
                            <img className='hidden dark:block' src="/assets/images/menu/arrow-left-dark.svg" alt="" />
                        </>
                    )
                }
                <HeadingComponent className="text-primary dark:text-white">{menus.title}</HeadingComponent>
            </div>

            <ul className="mt-12 space-y-4">
                {
                    menus.items.map(menu => {
                        return (
                            <li className="px-4 md:px-8 flex items-center border-b-color py-5 cursor-pointer border rounded-full" onClick={() => onClick(menu)}>
                                <img src={menu.icon} className='mr-2 w-6 h-6' alt="" />
                                <ParagraphComponent className="text-gray hidden md:block" fontSize="22px">{menu.title}</ParagraphComponent>
                                <ParagraphComponent className="text-gray block md:hidden" fontSize="18px">{menu.title}</ParagraphComponent>
                                <IonIcon icon={chevronForward} className="ml-auto text-gray" size="small" />

                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default MorePage;