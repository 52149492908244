import authSlice, { AuthSliceModel } from './slice/auth.slice';
import {
  applyMiddleware, configureStore,
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import globalSlice, { GlobalSliceModel } from './slice/global.slice';
import pximetriesSlice, { PximetriesSliceModel } from './slice/pximetries.slice';
import MedicalAdviceSlice from './slice/medical-advice.slice';
import DoctorAppointmentSlice from './slice/appointment.slice';
import userSlice, { UserSliceModel } from './slice/user.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import ApanaSlice, { ApanaSliceModel } from './slice/apana.slice';


const middleware = applyMiddleware(thunk);

export interface combineReducersModel {
  global: GlobalSliceModel,
  pximetries: PximetriesSliceModel,
  user: UserSliceModel,
  auth: AuthSliceModel,
  apana: ApanaSliceModel,
}

const store = configureStore({
  reducer: {
    global: globalSlice,
    pximetries: pximetriesSlice,
    user: userSlice,
    auth: authSlice,
    medicalAdvice: MedicalAdviceSlice,
    doctorAppointment: DoctorAppointmentSlice,
    apana: ApanaSlice,
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
