import { DoctorsModel } from "./../../models/profile/user.model";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { appointmentsRequest, appointmentsSendRequest, doctorRequest, removeAheadRequest } from "../thunk/doctor-appointment.thunk";
import { AppointmentsModel } from "../../models/appointments";

export interface DoctorAappointment {
  loading?: boolean;
  loadingSend?: boolean;
  loadingGet?: boolean;
  loadingAppointmentId?: number;
  
  doctors?: DoctorsModel[];
  appointments?: AppointmentsModel[];
}

const initialState: DoctorAappointment = {
  doctors: [],
  appointments: []
};

const DoctorAppointment = createSlice({
  name: "DoctorAppointment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doctorRequest.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(doctorRequest.fulfilled, (state, action) => {
      state.doctors = action.payload.data;
      state.loading = false;
    });

    builder.addCase(doctorRequest.rejected, (state) => {
      state.loading = false;
    });
    //=====================================================
    builder.addCase(appointmentsSendRequest.pending, (state) => {
      state.loadingSend = true;
    });

    builder.addCase(appointmentsSendRequest.fulfilled, (state, action) => {
      state.appointments = action.payload.data;
      state.loadingSend = false;
    });

    builder.addCase(appointmentsSendRequest.rejected, (state) => {
      state.loadingSend = false;
    });
    //=====================================================
    builder.addCase(appointmentsRequest.pending, (state) => {
      state.loadingGet = true;
    });

    builder.addCase(appointmentsRequest.fulfilled, (state, action) => {
      state.appointments = action.payload.data;
      state.loadingGet = false;
    });

    builder.addCase(appointmentsRequest.rejected, (state) => {
      state.loadingGet = false;
    });

    //=====================================================
    builder.addCase(removeAheadRequest.pending, (state, action) => {
      state.loadingAppointmentId = action.meta.arg.id;
    });

    builder.addCase(removeAheadRequest.fulfilled, (state, action) => {
      const id = action.meta.arg.id;
      state.appointments = state.appointments?.filter(f => f.id !== id);
      state.loadingAppointmentId = 0;
    });

    builder.addCase(removeAheadRequest.rejected, (state) => {
      state.loadingAppointmentId = 0;
    });

  },
});

export const doctorAppointmentState = (state: RootState) =>
  state.doctorAppointment;
export const {} = DoctorAppointment.actions;
export default DoctorAppointment.reducer;
