import React, { useState } from "react";
import { BASEURLASSETS } from "../../../api/url.helper";
import { DoctorsModel } from "../../../models/profile/user.model";
import { useAppSelector } from "../../../store";
import { userState } from "../../../store/slice/user.slice";
import Modal from "../../modal-tailwind";
import SelectDoctor from "../../select-doctor";
import HeadingComponent from "../../typography/heading/heading.component";
import ParagraphComponent from "../../typography/paragraph/paragraph.component";
import DatePickerComponent from "../date-picker/date-picker";

type AppointmentModalPorps = {
  show?: boolean;
  name?: string;
  doctors?: DoctorsModel[];
  onSubmit?: (doctor_id: number, date: string) => void;
  onClose?: () => void;
};

const AppointmentModal = (props: AppointmentModalPorps) => {
  const [calenarShow, setCalendarShow] = useState<boolean>(false);
  const [date, setDate] = useState<string>("");
  const [doctorId, setDoctorId] = useState<string>();
  const { profile } = useAppSelector(userState);
  const onChangeDate = (date: any) => {
    setDate(date);
  };

  const onSubmit = () => {
    if (!props.onSubmit) return;

    if (!doctorId || Number(doctorId) < 1) {
      alert("please select doctor");
      return;
    }

    if (!date) {
      alert("please select date");
      return;
    }

    props.onSubmit(Number(doctorId), date);
  };


  return (
    <>
      <Modal show={props.show} onClose={props.onClose}>
        <div className="p-4 md:p-8 shadow space-y-4 md:space-y-8">
          <div className="flex justify-between">
            <ParagraphComponent fontSize="20px" color="#1D4068">
              Select a date for
            </ParagraphComponent>
            <img
              src="/assets/images/close.svg"
              className="cursor-pointer"
              onClick={props.onClose}
              alt=""
            />
          </div>
          <div className="flex gap-3">
            <img src={BASEURLASSETS + profile?.img} alt="" className="w-14 rounded-full" />
            <div className="flex flex-col items-start justify-center">
              <HeadingComponent fontSize="20px" className="text-primary dark:text-black" changeClassColor={true}>
                {profile?.name}
              </HeadingComponent>
              {/* <ParagraphComponent color="#868167">{profile?.extra?.user_dateOfPregnancy}</ParagraphComponent> */}
            </div>
          </div>

          <SelectDoctor doctors={props.doctors} onSelect={(doctor_id) => setDoctorId(doctor_id.toString())} />

          <div className="flex gap-8">
            <ParagraphComponent color="#A3A3A3">
              Date and time
            </ParagraphComponent>
            {/*  onClick={() => setCalendar({ ...calendar, show: true })} */}
            <div
              className="flex justify-between items-center w-full gap-4 cursor-pointer"
              onClick={() => setCalendarShow(true)}
            >
              <p className="border-b border-b-color pb-2 w-full text-black">
                {date}
              </p>
              <img
                src="/assets/images/message/calendar.svg"
                className="w-3 h-3"
                alt=""
              />
            </div>
          </div>
          <div className="flex justify-end items-end">
            <div
              className="bg-primary flex py-4 px-8 cursor-pointer rounded-full text-white gap-2"
              onClick={onSubmit}
            >
              <ParagraphComponent fontSize="16px" className="font-bold">
                send
              </ParagraphComponent>
              <img src={`/assets/images/medical-advice.svg`} alt="" />
            </div>
          </div>
        </div>
      </Modal>


      <DatePickerComponent
        isShow={calenarShow}
        onSelect={onChangeDate}
        onClose={() => setCalendarShow(false)}
        showTimeSelect={true}
        format='YYYY-MM-DD hh:mm:a'
      // isTime={true}
      // timeIterval={30}
      // // fromDate={calendar.fromDate}
      // time={calendar.isFrom ? calendar.fromDate : calendar.toDate}
      />
    </>
  );
};

export default AppointmentModal;
