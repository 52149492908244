import { createSlice } from "@reduxjs/toolkit";
import { combineReducersModel, RootState } from "..";
// import { Dictionary, PximetriesValueModel } from "../../models/oximetries/report.model";

export interface ApanaSliceModel {
  apana: boolean;
  connectedDevice?: number;
}

const initialState: ApanaSliceModel = {
  apana: false,
  connectedDevice: -1,
};


const ApanaSlice = createSlice({
  name: "ApanaSlice",
  initialState,
  reducers: {
    setActiveApana(state, { payload }) {
      state.apana = payload;
    },
    clearActiveApana: (state) => {
      state.apana = false;
    },
    setConnectedDevice(state, { payload }) {
      state.connectedDevice = payload;
    },
    clearConnectedDevice: (state) => {
      state.connectedDevice = -1;
    },
  },
});

export const apanaState = (state: RootState) => state.apana;

export const { setActiveApana, clearActiveApana, setConnectedDevice, clearConnectedDevice } = ApanaSlice.actions;
export default ApanaSlice.reducer;
