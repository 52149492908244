import React from 'react'
import { TabType } from '../../models/types/tab';

type TabsProps = {
  tab: TabType;
  onChange?: (tab: TabType) => void;
}

const Tabs = (props: TabsProps) => {

  const menus: (TabType)[] = ["heart-rate", "medical-advice", "doctor-appointment"]

  const onChange = (tab: TabType) => {
    if (!props.onChange) return;
    props.onChange(tab)
  }

  return (
    <div className="bg-main dark:bg-gray px-2 py-2 rounded-tr-3xl rounded-tl-3xl relative z-10 -mt-8">
      <ul className="grid grid-cols-3 items-center bg-gray-gold dark:bg-black rounded-3xl md:rounded-full p-1.5 md:p-2">
        {
          menus.map((menu, index) => {
            return (
              <li
                key={index}
                className={`cursor-pointer rounded-full py-2 w-full text-center ${props.tab === menu ? "bg-primary text-white" : "text-primary dark:text-white"
                  }`}
                onClick={() => onChange(menu)}
              >
                <p dangerouslySetInnerHTML={{ __html: menu.replace('-', '<br />') }}></p>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}
export default Tabs