import { useEffect, useState } from 'react';
import Input from '../../components/input/input';
import LogoComponent from '../../components/logo/logo.component';
import ParagraphComponent from '../../components/typography/paragraph/paragraph.component';
import Routes from '../../constant/route.constant';
import { useAppDispatch, useAppSelector } from '../../store';
import { authState } from '../../store/slice/auth.slice';
import { verifyRequest } from '../../store/thunk/auth.thunk';
import { setModal } from '../../store/slice/global.slice';
import ButtonCircle from '../../components/Molecules/button';
import ContentTemplate from '../../components/Templates/content';
import { getTime } from '../../utils/date';
import { useTimer } from '../../hook/useTimer';
import { useRouter } from '../../hook/useRouter';

const OtpPage = () => {
  const dispatch = useAppDispatch();
  const { email, response: requestResponse, loading } = useAppSelector(authState);
  const [code, setCode] = useState<string>('');
  const { time, start } = useTimer();

  const router = useRouter();

  useEffect(() => {
    if (!email) {
      router.push(Routes.login);
      return;
    }
    start(requestResponse?.seconds ?? 120);
    
  }, []);

  const submit = () => {
    if (!email) return;
    if (!code) {
      dispatch(setModal({ message: "Please enter the code." }))
      return;
    }

    dispatch(verifyRequest({ email, code })).then((response) => {
      if (response.meta.requestStatus === 'rejected') {
        const { data } = response.payload as { data: { message: string } };
        dispatch(setModal({ message: data.message }))
        return;
      }
      const { data } = response.payload as { data: { user_exists: boolean, message: string } };
      if (data.user_exists === true) {
        router.push(Routes.informationPage);
        return;
      }
      router.push(Routes.home);
    });
  };

  return (
    <ContentTemplate>
      <div className="h-screen flex flex-col items-center">
        <div className="flex flex-col justify-between items-center h-3/4 my-auto">
          <LogoComponent className="opacity-30" />
          <div className="space-y-4 text-center w-72">
            <h3 className={`${requestResponse?.success === true ? "text-green" : "text-green"}`}>{requestResponse?.message}</h3>
            <p>
              {getTime(time)}
            </p>
            <Input
              type="number"
              placeholder="Please enter code"
              value={code}
              onChange={(text) => setCode(text)}
              readOnly={false}
              align="text-center"
            />
            <ParagraphComponent
              className="text-gold cursor-pointer"
              onClick={() => router.push(Routes.login, true)}
            >
              change email address
            </ParagraphComponent>
            <ButtonCircle onClick={submit} loading={loading} />
          </div>
        </div>
      </div>
    </ContentTemplate>
  );
};

export default OtpPage;
