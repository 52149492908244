import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserInfoModel } from '../../models/profile/user.model';
import { setModal } from '../../store/slice/global.slice';
import DatePickerComponent from '../Molecules/date-picker/date-picker';
import Input from '../input/input';
import ParagraphComponent from '../typography/paragraph/paragraph.component';
import { BASEURLASSETS } from '../../api/url.helper';
import ButtonCircle from '../Molecules/button';

type InformationFormProps = {
    data?: UserInfoModel;
    isProfile?: boolean;
    loading?: boolean;
    cropData?: string;
    onSubmit?: (data: UserInfoModel) => void;
    onSelectFile?: (data: File) => void;
}

const InformationForm = (props: InformationFormProps) => {

    const dispatch = useDispatch();

    const [data, setData] = useState<UserInfoModel>(props.data ?? {});
    const [calendar, setCalendar] = useState<{ type?: "birth" | "pregnancy", active?: boolean }>({ active: false });

    useEffect(() => {
        setData(props.data ?? {});
    }, [props.data])

    const onSubmit = () => {

        if (!props.onSubmit) return;

        if (!data.name) {
            dispatch(setModal({ message: "Please enter your name." }));
            return;
        }

        if (!data.extra?.user_dateOfBirth) {
            dispatch(setModal({ message: "Please enter your date of birth." }));
            return;
        }

        if (!data.extra?.user_height) {
            dispatch(setModal({ message: "Please enter your height." }));
            return;
        }

        if (!data.extra?.user_weight) {
            dispatch(setModal({ message: "Please enter your weight." }));
            return;
        }


        if (!data.extra?.user_dateOfPregnancy) {
            dispatch(setModal({ message: "Please enter your date of pregnancy." }));
            return;
        }
        if (!data.extra.user_address) {
            dispatch(setModal({ message: "Please enter your address." }));
            return;
        }

        if (!data.extra.user_medical_records) {
            dispatch(setModal({ message: "Please enter your medical records." }));
            return;
        }

        props.onSubmit(data);

    }

    const editIcon = () => {

        if (!props.isProfile) return <></>;

        return <img src="/assets/images/profile/edit.svg" />;
    }

    const onFile = (files: FileList | null) => {
        if (!files || files.length === 0) return;
        if (props.onSelectFile) props.onSelectFile(files[0]);
    }

    const getImage = () => {
        if (props.data?.img) return BASEURLASSETS + props.data?.img;
        return BASEURLASSETS + "images/users/placeholder.png";
    }

    return (
        <div className="space-y-8">
            {
                calendar && (

                    <DatePickerComponent
                        isShow={calendar.active}
                        showTimeSelect={false}
                        format='YYYY-MM-DD'
                        onSelect={(value) => {
                            if (calendar.type === "birth") {
                                setData({ ...data, extra: { ...data.extra, user_dateOfBirth: value } });
                            } else {
                                setData({ ...data, extra: { ...data.extra, user_dateOfPregnancy: value } });
                            }
                        }}
                        date={calendar.type === "birth" ? (data.extra?.user_dateOfBirth ? new Date(data.extra.user_dateOfBirth) : undefined) : (data.extra?.user_dateOfPregnancy ? new Date(data.extra.user_dateOfPregnancy) : undefined)}
                        onClose={() => setCalendar({ ...calendar, active: false })}
                    />
                )
            }
            <div className="flex gap-5 items-center">
                <ParagraphComponent className="col-span-3 text-gray text-left w-24">Photo</ParagraphComponent>
                <div className="col-span-7 text-left">
                    <label htmlFor="profile-image" className='cursor-pointer'>
                        <input type="file" name="" id="profile-image" className='hidden' onChange={(e) => onFile(e.target.files)} />
                        <div className="flex gap-5">
                            <img className="w-32 inline-block relative z- rounded-2xl" src={getImage()} alt="" />
                            {editIcon()}
                        </div>
                    </label>
                </div>
                <div className="col-span-2 flex justify-end">
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-start md:items-center">
                <ParagraphComponent className="text-gray text-left w-24">Name</ParagraphComponent>
                <Input
                    type="text"
                    placeholder="Name"
                    value={data.name}
                    onChange={(text) => setData({ ...data, name: text })}
                    readOnly={false}
                />
                {editIcon()}
            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-start md:items-center">
                <ParagraphComponent className="text-gray text-left w-24">Date of Birth</ParagraphComponent>
                <Input
                    type="text"
                    placeholder="Date of Birth"
                    value={data.extra?.user_dateOfBirth}
                    onChange={(text) => setData({ ...data, extra: { ...data.extra, user_dateOfBirth: text } })}
                    readOnly={true}
                    onClick={() => setCalendar({ type: "birth", active: true })}
                />
                {editIcon()}

            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-start md:items-center">
                <ParagraphComponent className="text-gray text-left w-24">Height</ParagraphComponent>
                <Input
                    type="text"
                    placeholder="Height"
                    value={data.extra?.user_height}
                    onChange={(text) => setData({ ...data, extra: { ...data.extra, user_height: text } })}
                    readOnly={false}
                />
                {editIcon()}

            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-start md:items-center">
                <ParagraphComponent className="text-gray text-left w-24">Weight</ParagraphComponent>
                <Input
                    type="text"
                    placeholder="Weight"
                    value={data.extra?.user_weight}
                    onChange={(text) => setData({ ...data, extra: { ...data.extra, user_weight: text } })}
                    readOnly={false}
                />
                {editIcon()}

            </div>
            {/* <div className="flex gap-6 md:gap-10 items-center"> */}
            <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-start md:items-center">
                <ParagraphComponent className="text-gray text-left w-24">Date of pregnancy</ParagraphComponent>
                <Input
                    type="text"
                    placeholder="Date of pregnancy"
                    value={data.extra?.user_dateOfPregnancy}
                    onChange={(text) => setData({ ...data, extra: { ...data.extra, user_dateOfPregnancy: text } })}
                    readOnly={true}
                    onClick={() => setCalendar({ type: "pregnancy", active: true })}
                />
                {editIcon()}

            </div>

            <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-start md:items-center">
                <ParagraphComponent className="text-gray text-left w-24">Email</ParagraphComponent>
                <Input
                    type="text"
                    placeholder="Email"
                    value={props.data?.email}
                    readOnly={true}
                />
                {editIcon()}

            </div>

            <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-start md:items-center">
                <ParagraphComponent className="text-gray text-left w-24">Address</ParagraphComponent>
                <Input
                    type="text"
                    placeholder="Address"
                    value={data?.extra?.user_address}
                    onChange={(text) => setData({ ...data, extra: { ...data.extra, user_address: text } })}
                    readOnly={false}
                />
                {editIcon()}

            </div>

            <div className="flex flex-col md:flex-row gap-4 md:gap-10 items-start md:items-center">
                <ParagraphComponent className="text-gray text-left w-24">Medical records</ParagraphComponent>
                <Input
                    type="text"
                    placeholder="Medical records"
                    value={data?.extra?.user_medical_records}
                    onChange={(text) => setData({ ...data, extra: { ...data.extra, user_medical_records: text } })}
                    readOnly={false}
                />
                {editIcon()}

            </div>

            <div className={`flex items-center justify-center`}>
                <ButtonCircle onClick={onSubmit} loading={props.loading} />
            </div>

        </div>
    )
}

export default InformationForm;