import { PayloadAction } from '@reduxjs/toolkit';
import { loginRequest, verifyRequest } from '../thunk/auth.thunk';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface AuthSliceModel {
  email?: string;
  loading?: boolean;
  response?: {
    message?: string;
    seconds?: number;
    success?: boolean;
  }
}

const initialState: AuthSliceModel = {
  email: undefined,
};

const AuthSlice = createSlice({
  name: 'AuthSlice',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.email = action.meta.arg.email;
      state.response = action.payload.data;
    });
    builder.addCase(loginRequest.rejected, (state) => {
      state.loading = false;
    });
    //=====================================================
    builder.addCase(verifyRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyRequest.fulfilled, (state, action) => {
      state.loading = false;
      localStorage.setItem(
        'access_token',
        action.payload.data?.access_token + ''
      );
      localStorage.setItem('image', action.payload.data?.image + '');
    });
    builder.addCase(verifyRequest.rejected, state => {
      state.loading = false;
    });
  },
});

export const authState = (state: RootState) => state.auth;
export const { setEmail } = AuthSlice.actions;
export default AuthSlice.reducer;
