import React from "react";
import { AppointmentsModel } from "../../models/appointments";
import LoadingSvg from "../Atoms/loading";
import HeadingComponent from "../typography/heading/heading.component";
import ParagraphComponent from "../typography/paragraph/paragraph.component";

type DoctorAppointmentProps = {
  appointments?: AppointmentsModel[];
  onRemove?: (id: number) => void;
  loadingAppointmentId?: number;
};

const DoctorAppointment = (props: DoctorAppointmentProps) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getMonth = (date: string) => {
    const _date = new Date(date);
    var month = _date.getUTCMonth();
    return monthNames[month];
  };

  const getDay = (date: string) => {
    const _date = new Date(date);
    var day = _date.getUTCDate();
    return day;
  }

  const getStatus = (status: "done" | "canceled" | "ahead") => {
    switch (status) {
      case "ahead":
        return { backgroundColor: "#FEF1BE", color: "#AC9642" }
      case "done":
        return { backgroundColor: "#CCFCD9", color: "#4D9861" }
      case "canceled":
        return { backgroundColor: "#F8E6D9", color: "#FD7279" }
    }
  }

  const getBackground = (status: "done" | "canceled" | "ahead") => {
    switch (status) {
      case "ahead":
        return { backgroundColor: "#FEB052" }
      case "done":
        return { backgroundColor: "#4994FC" }
      case "canceled":
        return { backgroundColor: "#FD55BE" }
    }
  }

  const getIcon = (status: "done" | "canceled" | "ahead") => {
    switch (status) {
      case "ahead":
        return "time-circle.svg";
      case "done":
        return "check.svg";
      case "canceled":
        return "close.svg";
    }
  }

  const onRemove = (id: number) => {
    if (!props.onRemove) return;
    props.onRemove(id)
  }

  return (
    <div className="px-4 mt-4 space-y-6">
      {props.appointments &&
        props.appointments.map((appointment) => {
          return (
            <div className="grid grid-cols-12 gap-3" key={appointment.id}>
              <div
                className="col-span-5 md:col-span-4 rounded-3xl flex flex-col justify-center items-center h-28"
                style={getBackground(appointment.status)}
              >
                <HeadingComponent
                  fontSize="50px"
                  className="text-white opacity-85"
                >
                  {getDay(appointment.reserve_time)}
                </HeadingComponent>
                <ParagraphComponent
                  fontSize="14px"
                  className="text-white opacity-60"
                >
                  {getMonth(appointment.reserve_time)}
                </ParagraphComponent>
              </div>
              <div className="col-span-7 md:col-span-8 flex flex-col items-start justify-center space-y-2">
                <div className="md:w-full flex flex-col md:flex-row md:items-center justify-between">
                  <div>
                    <HeadingComponent fontSize="20px" className="text-primary">
                      {appointment.name}
                    </HeadingComponent>
                    <ParagraphComponent className="text-gray">{appointment.reserve_time}</ParagraphComponent>
                  </div>
                  <div className="space-y-1">
                    <div
                      className="border flex p-1 rounded-xl gap-2 px-2 md:px-auto"
                      style={getStatus(appointment.status)}
                    >
                      <img
                        src={`/assets/images/doctor-appointment/${getIcon(appointment.status)}`}
                        alt=""
                      />
                      <ParagraphComponent className="text-text dark:text-black" changeColorClass={true}>
                        {appointment.status}
                      </ParagraphComponent>
                    </div>
                    {
                      appointment.status === 'ahead' && (
                        <div
                          onClick={() => onRemove(appointment.id)}
                          className="border flex p-1 rounded-xl gap-2 px-2 md:px-auto cursor-pointer"
                          style={getStatus('canceled')}
                        >
                          {
                            props.loadingAppointmentId === appointment.id ? (
                              <div className="h-5 w-3 flex justify-strat items-center"><LoadingSvg color="red" transform="scale(0.8)" /></div>
                            ) : (
                              <img
                                className="h-5 w-3"
                                src={`/assets/images/doctor-appointment/${getIcon('canceled')}`}
                                alt=""
                              />
                            )
                          }
                          <ParagraphComponent className="text-text dark:text-black" changeColorClass={true}>Remove</ParagraphComponent>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DoctorAppointment;
