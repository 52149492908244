import React, { useEffect, useState } from "react";
import { useIonRouter } from "@ionic/react";
import LoadingSvg from "../../../components/Atoms/loading";
import DashboardTemplate from "../../../components/dashboard-template";
import ReportSpo2Component from "../../../components/report-spo2/report-spo2.component";
import Routes from "../../../constant/route.constant";
import { useAppSelector, useAppDispatch } from "../../../store";
import { apanaState } from "../../../store/slice/apana.slice";
import { pximetriesState } from "../../../store/slice/pximetries.slice";
import { report } from "../../../store/thunk/oximetries.thunk";

const HomePage = () => {
  const { connectedDevice } = useAppSelector(apanaState);
  const { pximetries } = useAppSelector(pximetriesState);
  const [time, setTime] = useState<boolean>(false);
  const router = useIonRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    _report();
  }, []);

  const _report = () => {

    const oximetryType = [2]
      ?.map((item) => 'oximetry_types[]=' + item)
      .join('&');

    dispatch(report("group=true&" + oximetryType)());
  };

  useEffect(() => {
    if (connectedDevice !== -1) {
      const interval = setInterval(() => {
        const params = "group=true&oximetry_types[]=2&automatic=" + (connectedDevice !== -1);
        dispatch(report(params)());
      }, 20000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [connectedDevice, time]);


  const belt = () => {
    if (connectedDevice === undefined || connectedDevice <= 0) return 'There is no connected device, (connect a belt)';
    return 'There is connected device, (feching data ...)';
  }

  const connectBelt = () => {
    router.push(Routes.wristbandConnections.path);
  }

  return (
    <DashboardTemplate tab='heart-rate'>

      <p onClick={connectBelt} className="flex items-center justify-center gap-1 my-3 text-center text-sm hover:text-blue-500 cursor-pointer">
        {
          connectedDevice !== undefined && connectedDevice > 0 && (
            <LoadingSvg color="black" className="" />
          )
        }
        {belt()}
        {/* {
          connectedDevice !== undefined && connectedDevice > 0 && (
            <>
              <svg width="18px" height="18px" viewBox="0 0 1024 1024">
                <path d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 0 0-11.3 0L209.4 249a8.03 8.03 0 0 0 0 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z" />
              </svg>
            </>
          )
        } */}
      </p>
      <ReportSpo2Component
        pximetries={pximetries}
      />
    </DashboardTemplate>
  );
};

export default HomePage;