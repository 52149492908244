import { IRequestConfig } from '..';
import { USERUPDATImageWURL, USERUPDATWURL, USERURL } from '../url.helper';

namespace ListProfile {
  // await AxiosHelper.post<ResponseModel<{ new_user?: boolean }>>(LOGINURL, param)
  export const getProfileRequest = (): IRequestConfig => {
    return {
      url: USERURL,
      method: 'GET',
      key: 'app/user',
      payload: 'data',
    };
  };
  export const updateProfileRequesturl = (): IRequestConfig => {
    return {
      url: USERUPDATWURL,
      method: 'PATCH',
      key: 'app/user/update',
      payload: 'data',
    };
  };

  export const updateProfileImageRequesturl = (): IRequestConfig => {
    return {
      url: USERUPDATImageWURL,
      method: 'PATCH',
      key: 'app/user/update-profile-image',
      payload: 'data',
    };
  };
}

export default ListProfile;
