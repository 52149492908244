import React from 'react'
import { DoctorsModel } from '../../models/profile/user.model';
import ParagraphComponent from '../typography/paragraph/paragraph.component';

type SelectDoctorProps = {
  onSelect?: (id: number) => void;
  doctors: DoctorsModel[] | undefined
}

const SelectDoctor = (props: SelectDoctorProps) => {

  const onSelect = (id: string) => {
    if (!props.onSelect) return;
    props.onSelect(Number(id));
  }

  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-8">
      <ParagraphComponent color="#A3A3A3">Doctor’s name</ParagraphComponent>
      <select
        name=""
        id=""
        onChange={(e) => onSelect(e.target.value)}
        className="w-full bg-transparent border-b border-b-color text-black"
      >
        <option value="-1">please chose</option>
        {props.doctors && props.doctors.map((doctor) => {
          return (
            <option key={doctor.id} value={doctor.doctor_id}>
              {doctor.name}
            </option>
          );
        })}
      </select>
    </div>
  )
}

export default SelectDoctor