import React from 'react';
import { PximetriesModel } from '../../models/oximetries/report.model';
import { getDate } from '../../utils/date';
import HeadingComponent from '../typography/heading/heading.component';
import ParagraphComponent from '../typography/paragraph/paragraph.component';

import "./report-spo2.component.scss";

type ReportSpo2ComponentProps = {
    pximetries?: PximetriesModel[];
    onDetail?: (status: boolean) => void;
}

const ReportSpo2Component = (props: ReportSpo2ComponentProps) => {

    if (!props.pximetries || props.pximetries.length === 0) return <h1 className="text-center my-4">There is no data to display.</h1>

    return (
        <div className="my-4 report-spo2 max-w-3xl mx-4 md:mx-auto">
            <ul className="space-y-4">
                {
                    props.pximetries && props.pximetries.map((pximetries) => {
                        return (
                            pximetries.values && pximetries.values.map((pximetrie, jIndex) => {
                                return (
                                    <li key={jIndex} className={`grid grid-cols-2 items-center border border-gray rounded-3xl px-3 md:px-8 py-3 md:py-6`}>
                                        <div className="">
                                            <ParagraphComponent className="text-gray-text mb-4">{getDate(pximetrie.date ?? "")}</ParagraphComponent>
                                            <div className="flex items-center justify-start gap-2">
                                                <img src="/assets/images/home/heart.png" className="w-5 h-5" />
                                                <div className='flex items-end justify-center gap-2'>
                                                    <HeadingComponent className="text-primary">{Math.round(Number(pximetrie.value ?? "0"))}</HeadingComponent>
                                                    <ParagraphComponent className='text-gray2'>AVG</ParagraphComponent>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <img src={`assets/images/chart/line2.png`} className="h-16" alt="" />
                                        </div>
                                    </li>
                                )
                            })
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default ReportSpo2Component;


