import { DoctorsModel } from './../../models/profile/user.model';
import { PximetriesValueModel } from './../../models/oximetries/report.model';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { combineReducersModel } from '..';
import { UserInfoModel } from '../../models/profile/user.model';
import { profileRequest, updateProfileImageRequest, updateProfileRequest } from '../thunk/profile.thunk';
import { doctorsRequest, userTodoctorsRequest } from '../thunk/user.thunk';

export interface UserSliceModel {
  profile?: UserInfoModel;
  extra?: PximetriesValueModel[];
  loading: boolean;
  doctors?: DoctorsModel[];
}

const initialState: UserSliceModel = {
  profile: undefined,
  extra: [],
  loading: false,
  doctors: []
};

const UserSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: {
    setUser(state, { payload }) {
      state.profile = payload;
    },
    setExtra(state, action: PayloadAction<PximetriesValueModel[]>) {
      state.extra = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(profileRequest.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      // state.profile = action.payload.data;
      state.profile = action.payload.data;
    });

    builder.addCase(updateProfileImageRequest.fulfilled, (state, action: PayloadAction<any>) => {
      state.profile = {...state.profile, img: action.payload.data}
    });

    builder.addCase(doctorsRequest.fulfilled, (state, action) => {
      state.doctors = action.payload.data;
    });

    /// =====================================================
    builder.addMatcher(isAnyOf(
      profileRequest.pending,
      doctorsRequest.pending,
      userTodoctorsRequest.pending,
      updateProfileRequest.pending,
      updateProfileImageRequest.pending), (state, action) => {
        state.loading = true;
      })
    builder.addMatcher(isAnyOf(
      profileRequest.fulfilled,
      doctorsRequest.fulfilled,
      userTodoctorsRequest.fulfilled,
      updateProfileRequest.fulfilled,
      updateProfileImageRequest.fulfilled,
      profileRequest.rejected,
      doctorsRequest.rejected,
      userTodoctorsRequest.rejected,
      updateProfileRequest.rejected,
      updateProfileImageRequest.rejected), (state, action) => {
        state.loading = false;
      })
  },
});

export const userState = (state: combineReducersModel) => state.user;

export const { setUser, setExtra } = UserSlice.actions;
export default UserSlice.reducer;
