import axios from "axios";
import Routes from "../constant/route.constant";
import { BASEURL } from "./url.helper";


const AxiosHelper = axios.create({
    baseURL: BASEURL,
    timeout: 600000,
    withCredentials: false
});

const UNAUTHORIZED = 401;
// Add a request interceptor
AxiosHelper.interceptors.request.use(async (request) => {

    const accessToken = localStorage.getItem("access_token");

    request.headers.Authorization = `Bearer ${accessToken}`;
    console.log('==================================== request ');
    console.log(JSON.stringify(request));
    console.log('====================================');
    return request;
});
AxiosHelper.interceptors.response.use(async (response) => {

    console.log('==================================== response ');
    console.log(JSON.stringify(response));
    console.log('====================================');

    return response;
}, async (error) => {

    console.log('==================================== error ');
    console.log(JSON.stringify(error.response));
    console.log('====================================');

    const { status } = error.response;
    if (status === UNAUTHORIZED) {
        localStorage.clear();
        document.location.href = Routes.login.path;
    }

    return Promise.reject(error);
});


export default AxiosHelper;