import Service, { IRequestConfig } from '../../api/index';
import { createAsyncThunk } from "@reduxjs/toolkit";

const GenericThunk = <T, R = undefined>(request: IRequestConfig) => {
  return createAsyncThunk(request.key, async (params: R, thunkAPI) => {
    try {
      // const state = thunkAPI.getState();
      console.log("===================== request");
      console.log(JSON.stringify(request));
      console.log(JSON.stringify(params));

      const { data } = await Service.request<T, R>(request, params);
      return data;
    } catch (error: any) {
      console.log("===================== error");
      console.log(error);
      
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
};

export default GenericThunk;