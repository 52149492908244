import { IonImg } from "@ionic/react";
import React from "react";
import Routes, { RoutesType } from "../../constant/route.constant";
import ParagraphComponent from "../typography/paragraph/paragraph.component";
import "./tabbar.component.scss";

/**
 * برای گرادینت
 * https://cssgradient.io/
 */
interface ContainerProps {
  onRoute?: (name: RoutesType) => void;
  active?: string;
}

const TabbarComponent: React.FC<ContainerProps> = (props: ContainerProps) => {


  const route = (name: RoutesType) => {
    if (props.onRoute) {
      props.onRoute(name);
    }
  };
  return (
    <div className="tabbar-container">
      <div className="tabbar-content grid grid-cols-3">
        {/* <div className="text-white">-{props.active}-</div> */}

        <div className="cursor-pointer" onClick={() => route(Routes.profile)}>
          <IonImg
            src={`assets/images/tabbar/profile${props.active === Routes.profile.path ? "" : "-white"}.svg`}
            className="resize cursor-pointer"
          />
          <p className={`text-center mt-1 ${props.active === Routes.profile.path ? "text-primary dark:text-black" : "deactive text-red-600"}`}>Profile</p>
        </div>
        <div className="cursor-pointer" onClick={() => route(Routes.home)}>
          <IonImg
            src={`assets/images/tabbar/home${props.active === Routes.home.path ? "" : "-white"}.svg`}
            className="resize cursor-pointer"
          />
          <p className={`text-center mt-1 ${props.active === Routes.home.path ? "text-primary dark:text-black" : "deactive text-red-600"}`}>Home</p>
        </div>

        <div className="cursor-pointer" onClick={() => route(Routes.more)}>
          <IonImg
            src={`assets/images/tabbar/more${props.active === Routes.more.path ? "" : "-white"}.svg`}
            className="resize cursor-pointer"
          />
          <p className={`text-center mt-1 ${props.active === Routes.more.path ? "text-primary dark:text-black" : "deactive text-red-600"}`}>More</p>
        </div>

      </div>

    </div>
  );
};

export default TabbarComponent;
