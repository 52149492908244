import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderComponent from '../components/header/header.component';
import ModalComponent from '../components/modal/modal.component';
import TabbarComponent from '../components/tabbar/tabbar.component';
import Routes, { RoutesEnum, RoutesType } from '../constant/route.constant';
import { globalState, showMenu } from '../store/slice/global.slice';
import MenuModalComponent from '../components/menu/menu-modal.component';
import history from '../store/history';

type MasterLayoutProps = {
    children?: React.ReactNode;
}

const MasterLayout = (props: MasterLayoutProps) => {

    const router = useIonRouter();
    const dispatch = useDispatch();
    const { isMenu } = useSelector(globalState);

    const onRoute = (rout: RoutesType) => {

        if (rout.path === RoutesEnum.exit) {
            localStorage.clear();
            dispatch(showMenu(false));
            document.location.href = RoutesEnum.welcome;
            // history.push(Routes.welcome.path);
            return;
        }

        dispatch(showMenu(false));
        history.push(rout.path);
    }

    const getRoute = () => {

        const values: RoutesType[] = Object.values(Routes);

        let path = router.routeInfo.pathname;

        if (router.routeInfo.pathname.includes("dashboard/detail")) {
            path = "/dashboard/detail";
        }

        const find = values.find(f => f.path === path);
        // debugger;

        return find;
    }

    // const apanaActive = (status: boolean) => {
    //     dispatch(apanaUpdateRequest(status));
    // }


    return (
        <IonPage>

            <HeaderComponent route={getRoute()} openMenu={() => dispatch(showMenu(true))} />
            {props.children}


            <TabbarComponent onRoute={(name) => onRoute(name)} active={router.routeInfo.pathname} />

            <ModalComponent showModal={isMenu} onDidDismiss={() => dispatch(showMenu(false))}>
                <MenuModalComponent onRoute={(name) => onRoute(name)} />
            </ModalComponent>

        </IonPage>
    )
}

export default MasterLayout;