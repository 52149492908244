
export type RoutesType = {
    path: string;
    title?: string;
    isBack?: boolean;
}

export interface RoutesItems {
    root: RoutesType;
    login: RoutesType,
    otp: RoutesType,
    dashboard: RoutesType;
    welcome: RoutesType;
    home: RoutesType;
    medicalAdvice: RoutesType;
    doctorAppointment: RoutesType;
    profile: RoutesType;
    doctors: RoutesType;
    more: RoutesType;
    exit: RoutesType;
    wristbandConnections: RoutesType;
    informationPage: RoutesType;
}

export enum RoutesEnum {
    root = '/',
    login = '/auth/login',
    otp = '/auth/otp',
    dashboard = '/dashboard',
    welcome = '/welcome',
    home = '/dashboard/home',
    medicalAdvice = '/dashboard/medical-advice',
    doctorAppointment = '/dashboard/doctor-appointment',
    profile = '/dashboard/profile',
    doctors = '/dashboard/doctors',
    exit = 'exit',
    wristbandConnections = '/dashboard/wristband-connections',
    informationPage = '/auth/profile',
    morePage = '/dashboard/more',
}

const Routes: RoutesItems = {
    root: {
        path: RoutesEnum.root,
    },

    dashboard: {
        path: RoutesEnum.dashboard,
        title: "dashboard",
    },

    welcome: {
        path: RoutesEnum.welcome,
        title: "welcome",
    },
    home: {
        path: RoutesEnum.home,
        title: "home",
    },
    profile: {
        path: RoutesEnum.profile,
        title: "profile",
    },

    doctors: {
        path: RoutesEnum.doctors,
        title: "Doctors",
        isBack: true
    },
    exit: {
        path: RoutesEnum.exit,
    },
    wristbandConnections: {
        path: RoutesEnum.wristbandConnections,
        title: "Wristband Connections",
        isBack: true
    },
    login: {
        path: RoutesEnum.login,
        title: "Login"
    },
    otp: {
        path: RoutesEnum.otp,
        title: "Otp"
    },
    informationPage: {
        path: RoutesEnum.informationPage,
        title: "Profile Information"
    },

    more: {
        path: RoutesEnum.morePage,
        title: "More"
    },
    medicalAdvice: {
        path: RoutesEnum.medicalAdvice,
    },
    doctorAppointment: {
        path: RoutesEnum.doctorAppointment,
    }
}

export default Routes;