import { IRequestConfig } from "..";
import {
  MedicalAdviceSendURL,
  MedicalAdviceURL,
  USERDOCTORALLSURL,
  USERDOCTORREQUESTSURL,
  USERURL,
} from "../url.helper";

namespace UserService {
  export const profile = (): IRequestConfig => {
    return {
      url: USERURL,
      method: "GET",
      key: "app/user",
    };
  };

  export const medical_advice = (): IRequestConfig => {
    return {
      url: MedicalAdviceURL,
      method: "GET",
      key: "app/user/medical-advice/",
      payload: "params",
    };
  };

  export const medicalAdviceSendRequest = (): IRequestConfig => {
    return {
      url: MedicalAdviceSendURL,
      method: "POST",
      key: "app/user/medical-advice/send",
      payload: "data",
    };
  };

  export const doctors = (): IRequestConfig => {
    return {
      url: USERDOCTORALLSURL,
      method: "GET",
      key: "app/user/doctors"
    };
  };

  export const userTodoctorsRequest = (): IRequestConfig => {
    return {
      url: USERDOCTORREQUESTSURL,
      method: "POST",
      key: "app/user/doctors/request",
      payload: "data"
    };
  };
}

export default UserService;
