import { MenuModel } from './../../../models/menu/index';
import Routes from "../../../constant/route.constant";

export const MOREDATA: MenuModel[] = [
    {
        title: "Settings",
        icon: "/assets/images/menu/setting.svg",
        children: [
            {
                title: "Belt connections",
                icon: "/assets/images/menu/scan.svg",
                route: Routes.wristbandConnections.path
            }
        ]
    },
    {
        title: "Requests",
        icon: "/assets/images/tabbar/profile.svg",
        route: Routes.doctors.path
    },
    {
        title: "Exit",
        icon: "/assets/images/menu/logout.svg",
        route: Routes.exit.path
    }
]