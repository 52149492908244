import { useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Routes from '../../../constant/route.constant';
import { UserInfoModel } from '../../../models/profile/user.model';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setToast } from '../../../store/slice/global.slice';
import { userState } from '../../../store/slice/user.slice';
import { profileRequest, updateProfileRequest, updateProfileImageRequest } from '../../../store/thunk/profile.thunk';
import InformationForm from '../../information-form/information-form';
import CropperImage from '../../Molecules/cropper';

type InformationProps = {
    profilePage?: boolean;
}

const Information = (props: InformationProps) => {

    const dispatch = useAppDispatch();
    const { profile, loading } = useAppSelector(userState)
    const [image, setImage] = useState();
    const router = useIonRouter();

    useEffect(() => {
        dispatch(profileRequest());
    }, [])

    const update = (data: UserInfoModel) => {
        dispatch(updateProfileRequest(data))
            .unwrap()
            .then(response => {
                if (!props.profilePage) {
                    router.push(Routes.home.path);
                }
                dispatch(setToast({ message: response.message, status: true }))
            }).catch(e => {
                dispatch(setToast({ message: e.message, status: false }))
            });
    }

    const submitImage = (data: string) => {
        dispatch(updateProfileImageRequest({ image: data }))
            .unwrap()
            .then(response => {
                // if (response.meta.requestStatus === "rejected") return;
                setImage(undefined)
                dispatch(setToast({ message: response.message, status: true}))
            });
    }

    const onChange = (file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        reader.readAsDataURL(file);
    };
    return (
        <>
            {
                !!image ? (
                    <CropperImage onSubmit={submitImage} image={image} onCancel={() => setImage(undefined)} loading={loading} />
                ) : (
                    <div className={`items-center justify-center text-center relative z-10 ${!props.profilePage ? "px-4" : ""}`}>
                        <InformationForm loading={loading} onSubmit={update} isProfile={true} data={profile} onSelectFile={onChange} />
                    </div>
                )
            }
        </>
    )
}

export default Information;