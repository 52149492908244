import { MedicalAdviceModel } from "./../../models/medical_advice/index";
import ResponseModel from "../../models/response.model";
import GenericThunk from ".";
import UserService from "../../api/user";
import { DoctorsModel, UserModel } from "../../models/profile/user.model";

export const medicalAdviceRequest = GenericThunk<
  ResponseModel<MedicalAdviceModel[]>,
  { page?: number; limit?: number }
>(UserService.medical_advice());

export const medicalAdviceSenRequest = GenericThunk<
  ResponseModel<MedicalAdviceModel[]>,
  { page?: number; limit?: number }
>(UserService.medical_advice());

export const submitAdviceRequest = GenericThunk<
  ResponseModel,
  { message: string; to_user_id?: number; message_id?: number; doctorId?: string }
>(UserService.medicalAdviceSendRequest());

export const doctorsRequest = GenericThunk<
  ResponseModel<DoctorsModel[]>
>(UserService.doctors());

export const userTodoctorsRequest = GenericThunk<
  ResponseModel<DoctorsModel[]>, { doctor_id: number }
>(UserService.userTodoctorsRequest());