import { UserInfoModel } from './../../models/profile/user.model';
import AxiosHelper from '../../api/axios.helper';
import { setModal, startLoading, stopLoading } from '../slice/global.slice';
import { REPORTURL } from '../../api/url.helper';
import ResponseModel from '../../models/response.model';
import { setExtra } from '../slice/user.slice';
import { UserModel } from '../../models/profile/user.model';
import { PximetriesValueModel } from '../../models/oximetries/report.model';
import GenericThunk from '.';
import ListProfile from '../../api/profile';

export const profileRequest = GenericThunk<ResponseModel<UserModel>>(
  ListProfile.getProfileRequest()
);
export const updateProfileRequest = GenericThunk<
  ResponseModel<UserModel>,
  UserInfoModel
>(ListProfile.updateProfileRequesturl());

export const updateProfileImageRequest = GenericThunk<
  ResponseModel<string>,
  {image: string}
>(ListProfile.updateProfileImageRequesturl());
